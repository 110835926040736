import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonTable} from '../../../interfaces/common-table';
import {AppConfigService, BoErrorHandlerService, ConfirmDialogDisplayService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute} from '@angular/router';
import {
	PublishDrawResultRequest,
	PublishDrawResultsDTO,
	PublishDrawResultBase,
	WinnerBreakdown
} from '../../../models/games/publish-draw-results.model';
import {Subscription} from 'rxjs';
import {DataTableLazyLoadEvent} from '../../data-table-v3';
import {HttpParams} from '@angular/common/http';
import {DrawProcessingService, noGameLotteryImageURL} from '../../../../draw-processing';
import {formatDateWithLocale, getSystemLocale} from '../../../utilities/general-utilities/string.utility';
import {Confirmation} from 'primeng/api';
import {CurrencyPipe} from '@angular/common';
import {Draw, ToastMessageType} from '../../../models';

@Component({
	selector: 'he-publish-draw-results-tablog',
	templateUrl: './publish-draw-results-tablog.component.html',
	styleUrls: ['./publish-draw-results-tablog.component.scss']
})
export class PublishDrawResultsTablogComponent extends CommonTable<WinnerBreakdown> implements OnInit, AfterViewInit, OnDestroy {

	protected readonly getSystemLocale = getSystemLocale;
	protected readonly noGameLotteryImageURL = noGameLotteryImageURL;

	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() loading = false;
	@Input() selectedRow: Draw;

	@Output() closeEvent = new EventEmitter<void>();

	@Output() submitEvent = new EventEmitter<PublishDrawResultRequest>();

	submitLabel: string;
	canEnableSubmit = false;
	showConfirmationDialog = false;
	labelInterval: number;
	labelMsg: string;

	mainDrawData: PublishDrawResultsDTO;
	subDrawsData: PublishDrawResultBase[];

	drawIDs: number[];

	getTableDataSub$: Subscription;
	publishDrawResultsSub$: Subscription;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private drawProcessingService: DrawProcessingService,
				// private confirmationDisplayService: ConfirmDialogDisplayService,
				private currencyPipe: CurrencyPipe,
				private toastDisplayService: ToastDisplayService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.getTableData();
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getTableDataSub$, this.publishDrawResultsSub$);
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTableDataHelper();
	}

	private getTableDataHelper() {
		const drawID = this.selectedRow.drawID;
		// console.log('drawID =', drawID)
		this.getTableDataSub$ = this.drawProcessingService.getPublishableDraws(drawID).subscribe({
			next: res => {
				this.mainDrawData = res;
				this.subDrawsData = res.subDraws;

				/*if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}*/
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				// this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	fetchLookups() {
	}

	get canPublishAll() {
		if (!this.mainDrawData) {
			return false;
		}

		let flag = this.mainDrawData.isDrawPublishable;

		this.subDrawsData.forEach(draw => {
			flag = flag && draw.isDrawPublishable;
		});

		return flag;
	}

	invokeBtnLabelTimer(baseAcceptLabel: string) {
		this.submitLabel = baseAcceptLabel + ' - 00:05';

		if (this.labelInterval) {
			window.clearInterval(this.labelInterval);
		}

		let count = 5;

		this.labelInterval = window.setInterval(() => {
			count--;

			this.submitLabel = baseAcceptLabel + ` - 00:0${count}`;

			if (count <= -1) {
				this.submitLabel = baseAcceptLabel;
				this.canEnableSubmit = true;
				window.clearInterval(this.labelInterval);
			}
		}, 1000);
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	onPublishClicked(draw: PublishDrawResultBase) {
		const locale = getSystemLocale();
		const drawDate = formatDateWithLocale(draw.drawDate, locale);
		const amount = this.currencyPipe.transform(
			draw.totalMonetaryExposure, draw.currencySymbol, 'symbol', '1.0-2', locale);

		this.labelMsg = `Please confirm you wish to publish draw result for ${draw.gameGroupName} with: draw date ${drawDate},
		 and total monetary exposure of ${amount}.`

		this.showConfirmationDialog = true;
		this.drawIDs = [draw.drawID];
		this.invokeBtnLabelTimer('Yes')
	}

	onPublishAllResults() {
		if (!this.mainDrawData) {
			this.toastDisplayService.showFailureToast('Invalid state, can not proceed to publish all results.');
			return;
		}

		const drawIDs = [this.mainDrawData.drawID];

		let msg = `Please confirm you wish to publish draw results for:`

		//region TODO: Apply DRY to this section, below and that in onPublishClicked
		let locale = getSystemLocale();
		let drawDate = formatDateWithLocale(this.mainDrawData.drawDate, locale);
		let amount = this.currencyPipe.transform(
			this.mainDrawData.totalMonetaryExposure, this.mainDrawData.currencySymbol, 'symbol', '1.0-2', locale);

		msg = msg + ` -${1}- ${this.mainDrawData.gameGroupName} draw date ${drawDate}, and total monetary exposure of ${amount}`;
		//endregion

		this.subDrawsData.forEach((draw, index) => {
			drawIDs.push(draw.drawID);

			locale = getSystemLocale();
			drawDate = formatDateWithLocale(draw.drawDate, locale);
			amount = this.currencyPipe.transform(
				draw.totalMonetaryExposure, draw.currencySymbol, 'symbol', '1.0-2', locale);

			msg = msg + ` -${index + 2}- ${draw.gameGroupName} draw date ${drawDate}, and total monetary exposure of ${amount}`;
		});

		this.labelMsg = msg + '.';

		this.showConfirmationDialog = true;
		this.drawIDs = drawIDs;
		this.invokeBtnLabelTimer('Yes')
	}

	onSubmitConfirmation() {
		this.beforeTableDataServiceCall(this.publishDrawResultsSub$);

		if (!this.drawIDs || this.drawIDs?.length < 1) {
			console.error('PublishDrawResults: invalid drawIDs array.')
		}

		this.publishDrawResultsSub$ = this.drawProcessingService.publishDrawResults(this.drawIDs).subscribe({
			next: () => {
				this.drawIDs = undefined;

				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `Draw results published.`
				});

				// call since it is expected that the dialog should not be closed and
				// PublishDrawResultBase#isDrawPublishable should be set to false after publish
				this.getTableData();
			},
			error: error => {
				this.drawIDs = undefined;
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}
}
