import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonTable} from '../../../interfaces/common-table';
import {
	EWalletCreateRequest,
	EWalletDetails,
	EWalletProvider,
	EWalletUpdateRequest
} from '../../../models/finance/transfer-information.model';
import {Player} from '../../../models/player/players.model';
import {Subscription} from 'rxjs';
import {AppConfigService, BoErrorHandlerService, ConfirmDialogDisplayService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute} from '@angular/router';
import {BankCardService} from '../../../../player-management/shared/services/bank-card.service';
import * as ColumnNames from '../../../constants/ui-db-name-mappings';
import {ColumnType, DataTableActionCallback, DataTableLazyLoadEvent} from '../../data-table-v3';
import {Confirmation} from 'primeng/api';
import {DIALOG_CONFIRM_MSG_NORMAL} from '../../../constants/constants';
import {HttpParams} from '@angular/common/http';
import {ToastMessageType} from '../../../models';

@Component({
	selector: 'he-e-wallet-details-dialog',
	templateUrl: './e-wallet-details-dialog.component.html',
	styleUrls: ['./e-wallet-details-dialog.component.scss']
})
export class EWalletDetailsDialogComponent extends CommonTable<EWalletDetails> implements OnInit, OnDestroy {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() playerPartialData: Player;
	@Input() provider: EWalletProvider;

	@Output() closeEvent = new EventEmitter<void>();

	routePlayerID: number;

	showCreateDialog = false;
	showEditDialog = false;

	selectedRow: EWalletDetails;

	private routeSubs$: Subscription;
	private getTableDataSub$: Subscription
	private createEWalletSub$: Subscription
	private updateEWalletSub$: Subscription
	private deleteEWalletSub$: Subscription

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private bankCardService: BankCardService,
				private confirmationDisplayService: ConfirmDialogDisplayService,
				private toastDisplayService: ToastDisplayService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.initDataTable();

		this.routeSubs$ = this.route.params.subscribe(params => {
			this.routePlayerID = params.id;

			if (this.params) {
				this.getTableData(undefined);
			}
		});

		this.getTableData();
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.routeSubs$, this.getTableDataSub$, this.createEWalletSub$,
			this.updateEWalletSub$, this.deleteEWalletSub$
		);
	}

	initDataTable() {
		this.dataKey = ColumnNames.E_WALLET_ID.DB;

		this.cols = [
			{
				field: ColumnNames.E_WALLET_ID.DB,
				header: ColumnNames.E_WALLET_ID.UI
			},
			{
				field: ColumnNames.E_WALLET_PAYMENT_METHOD.DB,
				header: ColumnNames.E_WALLET_PAYMENT_METHOD.UI
			},
			{
				field: ColumnNames.E_WALLET_BENEFICIARY.DB,
				header: ColumnNames.E_WALLET_BENEFICIARY.UI
			},
			{
				field: ColumnNames.E_WALLET_ACCOUNT_REF.DB,
				header: ColumnNames.E_WALLET_ACCOUNT_REF.UI
			},
			{
				field: ColumnNames.E_WALLET_DATE_CREATED.DB,
				header: ColumnNames.E_WALLET_DATE_CREATED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.E_WALLET_DATE_LAST_USED.DB,
				header: ColumnNames.E_WALLET_DATE_LAST_USED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.E_WALLET_DATE_LAST_UPDATED.DB,
				header: ColumnNames.E_WALLET_DATE_LAST_UPDATED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_IS_DEFAULT.DB,
				header: ColumnNames.EXCHANGE_HOUSE_IS_DEFAULT.UI,
				columnType: ColumnType.TrueFalse
			}
		];

		this.tableActions = [
			{
				menuItem: {
					label: 'Edit',
					icon: 'pi pi-pencil'
				},
				callback: (callbackObj: DataTableActionCallback) => {
					this.selectedRow = callbackObj.data[0];
					this.showEditDialog = true;
				}
			},
			{
				menuItem: {
					label: 'Delete',
					icon: 'ui-icon-delete'
				},
				callback: (callbackObj) => {
					const selectedRow: EWalletDetails = callbackObj.data[0];

					const confMessage: Confirmation = {
						message: `${DIALOG_CONFIRM_MSG_NORMAL} This will delete eWallet with ID ${selectedRow.playerPaymentAccountID}.`,
						accept: () => {
							this.onDeleteEWallet(selectedRow.playerPaymentAccountID);
						},
						reject: () => {
							// Do nothing
						}
					};

					this.confirmationDisplayService.showConfirmDialog(confMessage);
				}
			}
		];

		this.searchFields = []

		this.fetchLookups();
	}

	fetchLookups() {
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTableDataHelper();
	}

	private getTableDataHelper() {
		this.getTableDataSub$ = this.bankCardService.getEWallets({
			playerID: this.routePlayerID,
			paymentMethodID: this.provider.paymentMethodID
		}, this.params).subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	onCreateEWalletDetailsSubmit(request: EWalletCreateRequest) {
		this.beforeTableDataServiceCall(this.createEWalletSub$);

		this.createEWalletSub$ = this.bankCardService.addEWallet(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'eWallet added.'
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}

	onEditEWalletDetailsSubmit(request: EWalletUpdateRequest) {
		this.beforeTableDataServiceCall(this.updateEWalletSub$);

		this.updateEWalletSub$ = this.bankCardService.updateEWallet(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'eWallet updated.'
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}

	onDeleteEWallet(playerPaymentAccountID: number) {
		this.loading = true;
		this.releaseSubscriptions(this.deleteEWalletSub$);

		this.deleteEWalletSub$ = this.bankCardService.deleteEWallet(playerPaymentAccountID).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `eWallet deleted.`
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}
}
