<!--region GameName skeleton and tag -->

<!--Only show titleSkeleton if loading && text is undefined or null-->
<ng-container *ngIf="loading && !gameNameTitle; else gameNameTag">
	<p-skeleton width="180px" height="63px"></p-skeleton>
</ng-container>

<ng-template #gameNameTag>
	<he-header headerTitle="{{ gameNameTitle ?? 'Encountered error whilst retrieving draw info ...'}}"></he-header>
</ng-template>

<!--endregion-->

<!--
<he-change-draw-date *ngIf="selectedDrawForCog" [draw]="selectedDrawForCog"
					 [(visible)]="drawOperations.displayChangeDrawDateDialog"
					 (closeEvent)="drawOperations.displayChangeDrawDateDialog = false"
					 (submitEvent)="drawOperations.changeDrawDate($event)">
</he-change-draw-date>
-->

<!--region Draw Dialogs-->

<!--Though not strictly required, *ngIf="" on the dialogs ensure that the views are destroyed when no longer visible,
    (1) keeps the DOM clean (2) Ensures that previous data of a particular row does not persistent-->
<he-drop-draw-dialog [dropType]="'cancel'" [drawID]="drawOpsImpl.selectedCogDraw?.drawID"
					 [visible]="drawOpsImpl.displayCancelDrawDialog"
					 (visibleChange)="drawOpsImpl.displayCancelDrawDialog = false"
					 (submitEvent)="cancelDraw($event)"></he-drop-draw-dialog>

<he-drop-draw-dialog [dropType]="'void'" [drawID]="drawOpsImpl.selectedCogDraw?.drawID"
					 [visible]="drawOpsImpl.displayVoidDrawDialog"
					 (visibleChange)="drawOpsImpl.displayVoidDrawDialog = false"
					 (submitEvent)="voidDraw($event)"></he-drop-draw-dialog>

<he-change-draw-date [draw]="drawOpsImpl.selectedCogDraw"
					 [visible]="drawOpsImpl.displayChangeDrawDateDialog"
					 (visibleChange)="drawOpsImpl.displayChangeDrawDateDialog = false"
					 (submitEvent)="changeDrawDate($event)"></he-change-draw-date>

<!--
he-manual-draw-entry-dialog Patterns t

	 Using "*ngIf="drawOpsImpl.displayInsertResultDialog && this.drawOpsImpl.gameFormat"" is clearly not compatible
     with visible binding hence why visible is used in tandem with visibleChange.

     Furthermore, the use of *ngIf="this.drawOpsImpl.gameFormat" is necessary to avoid a scenario where the
     component is declared before drawOpsImpl.gameFormat - which throws an error

     This can be potentially permanently rectified in he-manual-draw-entry-dialog by moving the
     this.gameFormat.gameBoards call on ngOnInit to an Input event when gameFormat changes,
     perhaps only taking action on the first-->
<he-manual-draw-entry-dialog
	[visible]="drawOpsImpl.displayInsertResultDialog && this.drawOpsImpl.gameFormat !== null"
	(visibleChange)="onInsertResultDialogVisChange($event)"
	[draw]="drawOpsImpl.selectedCogDraw"
	[gameFormat]="this.drawOpsImpl.gameFormat"
	[lotteryResults]="this.drawOpsImpl.lotteryResults"
	(submitEvent)="insertManualResults($event);"
	entryType="insertion"></he-manual-draw-entry-dialog>

<he-manual-draw-entry-dialog
	[visible]="drawOpsImpl.displayApproveResultDialog && this.drawOpsImpl.lotteryResults != undefined"
	(visibleChange)="onApproveResultDialogVisChange($event)"
	[draw]="drawOpsImpl.selectedCogDraw"
	[gameFormat]="drawOpsImpl.gameFormat"
	[lotteryResults]="drawOpsImpl.lotteryResults"
	(submitEvent)="approveManualResults($event)"
	entryType="approval"></he-manual-draw-entry-dialog>

<!--Without *ngIf here, the element is added in DOM and getTable is called before the rowItem has been clicked -
todo: fix visible behaviour due to bug-->
<he-publish-draw-results-tablog *ngIf="drawOpsImpl.displayPublishDrawResultDialog"
								[selectedRow]="drawOpsImpl.selectedCogDraw" [loading]="loading"
								[(visible)]="drawOpsImpl.displayPublishDrawResultDialog"
								(visibleChange)="onPublishDrawResultDialogVisChange($event)"
								(closeEvent)="drawOpsImpl.displayPublishDrawResultDialog = false"
								(submitEvent)="onPublishResultSubmit($event)"></he-publish-draw-results-tablog>


<!--endregion-->

<p-tabView [activeIndex]="activeTabIndex" (onChange)="handleTabChange($event)">
	<!--Don't include *ngIf="drawOfInterest" conditions on children of <p-tabPanel> - instead handle error
	emitting from undefined drawOfInterest of interest in this components so that they are global-->

	<p-tabPanel [header]="(drawType === MyDrawType.ACTIVE) ? 'Next Draw' : 'Latest Draw'"
				leftIcon="ui-icon-info">
		<ng-container *ngIf="activeTabIndex === 0">
			<ng-container *ngIf="loading; else drawCard">
				<div class="skeleton-catalogue">
					<p-skeleton shape="circle" size="120px"></p-skeleton>

					<div class="skeleton-draw-subtitles">
						<p-skeleton width="240px" height="22px" class="subtitle"></p-skeleton>
						<p-skeleton width="240px" height="22px" class="subtitle"></p-skeleton>
						<p-skeleton width="160px" height="22px" class="subtitle"></p-skeleton>
					</div>

					<div class="skeleton-additional-info">
						<ng-container *ngFor="let x of [1, 2, 3]">
							<p-skeleton width="80px" height="32px"></p-skeleton>
							<p-skeleton width="180px" height="32px"></p-skeleton>
						</ng-container>
					</div>
				</div>
			</ng-container>

			<ng-template #drawCard>
				<!-- he-draw-card is not to be initialised before nextUpcomingDraw is available-->
				<he-draw-card [gameDraw]="drawOfInterest" [includeViewMore]="false"
							  [drawType]="drawType" [compactStyle]="true"></he-draw-card>
			</ng-template>

			<div id="all-draws">
				<!--<ng-container *ngIf="loading">
					<he-skeleton-table></he-skeleton-table>
				</ng-container>-->

				<div>
					<h3 class="draw-proc-subtitle">{{ allDrawsTableTitle }}</h3>

					<!-- 1. Is Central OR more than 1 Tenant -->
					<!-- 2. this.drawOpsImpl.loading is used independent of loading, which is for table data loading,
					    so that these do not conflict with each other. I.e. after a draw op is successful, the new data
					    for table will not necessarily be available at the same time; worse it might return an err-->
					<he-data-table-v3 [dataKey]="dataKey" [canExportAll]="false"
									  [columns]="cols" [data]="tenantDraws" [lazy]="true"
									  (lazyLoad)="getTableData($event)" [loading]="loading || drawOpsImpl.loading"
									  [totalRecords]="totalRecords" [tableActions]="tableActions"
									  [tableMessage]="tableMessage" [searchFields]="searchFields"
									  [expandableRows]="hasMoreThanOneTenant">
						<ng-template let-rowData let-index="index">
							<he-data-table-v3 *ngIf="hasMoreThanOneTenant" class="he-nested-table"
											  deliverInitialLazyCall="true"
											  (lazyLoad)="getNestedTableData($event, rowData, index)"
											  [dataKey]="nestedDataKey" [data]="rowData.breakdown"
											  [loading]="nestedTables.get(index)?.loading"
											  [rowsPerPage]="100" [parentData]="rowData"
											  [columns]="nestedCols"
											  [totalRecords]="nestedTables.get(index)?.totalRecords"
											  [tableMessage]="nestedTables.get(index)?.tableMessage"
											  [searchFields]="nestedSearchFields"
											  [skeletonHasFooter]="false" [skeletonHasHeader]="false">
							</he-data-table-v3>
						</ng-template>
					</he-data-table-v3>
				</div>
			</div>
		</ng-container>
	</p-tabPanel>

	<p-tabPanel header="Sales" leftIcon="ui-icon-credit-card" [attr.unset-grid-tem-row]="true">
		<he-draw-sales *ngIf="activeTabIndex === 1" [gameDraw]="drawOfInterest" [drawType]="drawType"></he-draw-sales>
	</p-tabPanel>

	<p-tabPanel *ngIf="drawType === MyDrawType.PREVIOUS" header="Winners" leftIcon="ui-icon-grade"
				[attr.unset-grid-tem-row]="true">
		<he-draw-winners *ngIf="activeTabIndex === 2" [gameDraw]="drawOfInterest" class="ignore-rwd"></he-draw-winners>
	</p-tabPanel>
</p-tabView>
