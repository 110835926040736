import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {AppConfigService} from '../../helio-core-services';
import {FormatParams, ServiceAction, ServiceController} from '../utilities';
import {DataTableServerResponse, TableDataResponse} from '../components';
import {CSV_FORMAT, CURRENCY_TYPE_PARAM, FILTER_URL_PARAM, FORMAT_URL_PARAM, INCLUDE_CLOSED_ACCOUNTS, TAKE_URL_PARAM} from '../constants';
import {TenantV2} from '../models/player/tenant.model';
import {CurrencySetting, ResponseBodyType} from '../enums';
import {PlayerTrail} from '../models/player/player-trail.model';
import {removePrefixIfExists, stripWhiteSpace} from '../utilities/general-utilities/string.utility';
import {FreeTicketDTO, FreeTicketHistoryDTO} from '../models/player/free-ticket.model';
import {BaseResponse, UpdateResponse} from '../models/general/update-response';
import {WalletBalance} from '../models/finance/wallet-transaction.model';
import {PlayerParticipation} from '../models/player/player-participation.model';
import {delay, map} from 'rxjs/operators';
import {AccountSubscription} from '../models/player/subscription.model';
import {AccountRestriction, AddRestrictionRequest} from '../models/player/account-restriction.model';
import {dummyMessagesData} from './dummy-data/players.service.dummy';
import {Message} from '../models/player/message.model';
import {PlayerDetailsResponse, PlayerDetailsUpdateRequest} from '../models/player/player-details.model';
import {PlayerLifetimeData} from '../models/player/player-lifetime-data.model';
import {RewardPointsAdjustRequest, RewardPointsTransaction} from '../models/player/reward-points.model';
import {FreeTicketsAwardRequest} from '../models/player/free-tickets-award.model';
import {Kyc} from '../models/player/kyc.model';
import {Player, PlayerPasswordRequest} from '../models/player/players.model';
import {JsonPatchOperation} from '../interfaces/json-patch-operation';
import {BaseServiceImpl} from './base-service-impl';
import {UpdatePlayerCommRequest} from '../models/player/comm-preferences.model';

@Injectable({
	providedIn: 'root'
})
export class PlayersService extends BaseServiceImpl {

	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.PLAYER, appConfigService.serviceBaseURL);
	}

	getPlayer(playerID: number, includeClosedAccount: boolean = true): Observable<TableDataResponse<Player>> {
		const searchParams: HttpParams = new HttpParams()
			.set(FILTER_URL_PARAM, `playerId eq ${playerID}`)
			.set(TAKE_URL_PARAM, 1)
			.set(INCLUDE_CLOSED_ACCOUNTS, includeClosedAccount);

		return this.validateDataTableRes([], ServiceAction.PLAYERMANAGEMENT_GETPLAYERS, undefined, searchParams);
	}

	getOperator(playerID: number): Observable<TenantV2> {
		const searchParams: HttpParams = new HttpParams()
			.set('playerID', playerID);

		return this.validateAndParseObject<TenantV2>(
			['tenantID', 'tenantName'], undefined,
			undefined, searchParams, undefined, ServiceController.TENANTS
		);
	}

	getPlayers(searchParams?: HttpParams): Observable<TableDataResponse<Player>> {
		return this.validateDataTableRes([], ServiceAction.PLAYERMANAGEMENT_GETPLAYERS, undefined, searchParams);
	}

	getPlayersCsv(searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		return this.getCsv(
			ServiceAction.PLAYERMANAGEMENT_GETPLAYERS, undefined, searchParams,
			ResponseBodyType.Text, undefined, xFormatParams
		);
	}

	// playerID: number,
	getPlayerTransactions(currency: CurrencySetting = CurrencySetting.euroBase, searchParams?: HttpParams)
		: Observable<DataTableServerResponse> {
		const params = searchParams?.set(CURRENCY_TYPE_PARAM, currency) ?? undefined;

		return this.validateDataTableRes([], ServiceAction.PLAYERMANAGEMENT_PLAYERTRANSACTIONS, undefined, params);
	}

	/**
	 * @summary Presently, impl of GetPlayerParticipations, GetPlayerTransactions  and GetCouponTransactions
	 * require both csv in urlParam and format=csv!
	 */
	getPlayerTransactionsCsv(
		currency: CurrencySetting = CurrencySetting.euroBase,
		searchParams?: HttpParams,
		xFormatParams?: FormatParams[]): Observable<string> {
		let params = searchParams ?? new HttpParams();

		params = params.set(CURRENCY_TYPE_PARAM, currency)
			.set(FORMAT_URL_PARAM, CSV_FORMAT);

		return this.getCsv(ServiceAction.PLAYERMANAGEMENT_PLAYERTRANSACTIONS, undefined, params,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	/**
	 * Return transactions corresponding to the given {@link PlayerTrail#couponIdentifier} (aka TicketNumber)
	 */
	getCouponTransactions(couponID: string, currency: CurrencySetting = CurrencySetting.euroBase, searchParams?: HttpParams)
		: Observable<TableDataResponse<PlayerTrail>> {
		const coupon = stripWhiteSpace(couponID);
		let params = searchParams ?? new HttpParams();

		params = params.set(CURRENCY_TYPE_PARAM, currency);

		return this.validateDataTableRes([], ServiceAction.PLAYERMANAGEMENT_COUPONTRANSACTIONS, [coupon], params);
	}

	/**
	 * @summary Presently, impl of GetPlayerParticipations, GetPlayerTransactions  and GetCouponTransactions
	 * require both csv in urlParam and format=csv!
	 */
	getCouponTransactionsCsv(
		couponID: string,
		currency: CurrencySetting = CurrencySetting.euroBase,
		searchParams?: HttpParams,
		xFormatParams?: FormatParams[]): Observable<string> {
		const coupon = stripWhiteSpace(couponID);
		let params = searchParams ?? new HttpParams();

		params = params.set(CURRENCY_TYPE_PARAM, currency)
			.set(FORMAT_URL_PARAM, CSV_FORMAT);

		return this.getCsv(
			ServiceAction.PLAYERMANAGEMENT_COUPONTRANSACTIONS, [coupon], params,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	//region PlayerBalance

	// TODO - ng generate s player-balance
	/**
	 * @param id player ID
	 * @param searchParams http search and filter params
	 */
	getRewardPointTransactions(id: number, searchParams?: HttpParams): Observable<TableDataResponse<RewardPointsTransaction>> {
		// return this.get(ServiceAction.GET_REWARD_POINT_TRANS, [String(id)], searchParams,
		// 	ResponseBodyType.JSON, ServiceController.PLAYER_BALANCE
		// )

		return this.validateDataTableRes(
			['rewardTransactionID',],
			ServiceAction.GET_REWARD_POINT_TRANS, [String(id)], searchParams,
			ResponseBodyType.JSON, ServiceController.PLAYER_BALANCE
		);
	}

	getRewardPointTransactionsCsv(playerId: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]):
		Observable<string> {
		// const tempParams = searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT);
		return this.getCsv(ServiceAction.GET_REWARD_POINT_TRANS, [String(playerId)], searchParams,
			ResponseBodyType.Text, ServiceController.PLAYER_BALANCE, xFormatParams);
	}

	/**
	 * @param playerID playerID
	 * @param searchParams http search and filter params
	 */
	getFreeTicketHistory(playerID: number, searchParams?: HttpParams): Observable<TableDataResponse<FreeTicketDTO>> {
		let params: HttpParams = searchParams ? searchParams : new HttpParams();
		params = params.set('playerID', playerID);

		return this.validateDataTableRes(
			['gameGroupID', 'name', 'currentBalance'],
			ServiceAction.FREE_TICKETS_BAL, undefined, params,
			ResponseBodyType.JSON, ServiceController.FREE_TICKETS
		);
	}

	getFreeTicketHistoryCsv(playerID: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		let params: HttpParams = searchParams ? searchParams : new HttpParams();
		params = params.set('playerID', playerID);

		// const tempParams = searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT);
		return this.getCsv(ServiceAction.FREE_TICKETS_BAL, undefined, params,
			ResponseBodyType.Text, ServiceController.FREE_TICKETS, xFormatParams);
	}

	/**
	 * @param requestData playerID and gameGroupID. NB: Obj used to avoid mistaken input since both are type number.
	 * @param searchParams http search and filter params
	 */
	getFreeTicketHistoryForGame(requestData: {
		playerID: number,
		gameGroupID: number
	}, searchParams?: HttpParams): Observable<TableDataResponse<FreeTicketHistoryDTO>> {
		let params: HttpParams = searchParams ? searchParams : new HttpParams();

		params = params.set('playerID', requestData.playerID)
			.set('gameGroupID', requestData.gameGroupID);

		return this.validateDataTableRes(
			['stakeholderDisplayName', 'ticketsAdded'],
			ServiceAction.FREE_TICKETS_BAL_FOR_GAME, undefined, params,
			ResponseBodyType.JSON, ServiceController.FREE_TICKETS
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: any) => {
					// Parse Date object
					entry.timestamp = new Date(entry.timestamp);

					return entry;
				})

				return res as TableDataResponse<FreeTicketHistoryDTO>;
			}));
	}

	updateRewardPoints(playerID: number, data: RewardPointsAdjustRequest): Observable<BaseResponse> {
		return this.post(ServiceAction.ADJUST_REWARD_POINTS, data,
			undefined, [String(playerID)], ServiceController.PLAYER_BALANCE);
	}

	getBalance(id: number, searchParams?: HttpParams): Observable<WalletBalance> {
		return this.get(ServiceAction.GET_PLAYER_BALANCE, [String(id)], searchParams,
			ResponseBodyType.JSON, ServiceController.PLAYER_BALANCE);
	}

	//endregion

	getPlayerParticipations(playerID: number, currency: CurrencySetting = CurrencySetting.euroBase, searchParams?: HttpParams)
		: Observable<TableDataResponse<PlayerParticipation>> {
		const params = searchParams.set(CURRENCY_TYPE_PARAM, currency);
		return this.validateDataTableRes([], undefined, [playerID,
			ServiceAction.PLAYERMANAGEMENT_PLAYERPARTICIPATIONS], params);
	}

	/**
	 * @summary Presently, impl of GetPlayerParticipations, GetPlayerTransactions  and GetCouponTransactions
	 * require both csv in urlParam and format=csv!
	 */
	getPlayerParticipationsCsv(
		playerID: number,
		currency: CurrencySetting = CurrencySetting.euroBase,
		searchParams?: HttpParams, xFormatParams?: FormatParams[]
	): Observable<string> {
		const tempParams = searchParams
			.set(FORMAT_URL_PARAM, CSV_FORMAT)
			.set(CURRENCY_TYPE_PARAM, currency);

		return this.getCsv(undefined, [playerID, ServiceAction.PLAYERMANAGEMENT_PLAYERPARTICIPATIONS], tempParams,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	getPlayerPaymentPlans(searchParams?: HttpParams): Observable<TableDataResponse<any>> {
		return this.validateDataTableRes([], ServiceAction.PLAYERMANAGEMENT_PAYMENTPLANS, undefined, searchParams);
	}

	getPlayerPaymentPlansCsv(searchParams?: HttpParams): Observable<string> {
		return this.getCsv(ServiceAction.PLAYERMANAGEMENT_PAYMENTPLANS, ['csv'], searchParams, ResponseBodyType.Text);
	}

	getKycStatuses(searchParams: HttpParams): Observable<TableDataResponse<Kyc>> {
		return this.validateDataTableRes([], ServiceAction.PLAYERMANAGEMENT_PLAYERKYC, undefined, searchParams)
			.pipe(
				map(res => {
					res.resultSet = res.resultSet.map((kyc: Kyc) => {
						kyc.expiryDate = new Date(kyc.expiryDate);
						kyc.creationDate = new Date(kyc.creationDate);
						return kyc;
					});

					return res as TableDataResponse<Kyc>;
				})
			);
	}

	getKycStatusesCsv(searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		return this.getCsv(ServiceAction.PLAYERMANAGEMENT_PLAYERKYC, undefined, searchParams, ResponseBodyType.Text,
			undefined, xFormatParams);
	}

	getTransactionalKycStatuses(searchParams: HttpParams): Observable<TableDataResponse<Kyc>> {
		return this.validateDataTableRes([], ServiceAction.PLAYER_MGMT_TRANSACTIONAL_KYC, undefined, searchParams)
			.pipe(
				map(res => {
					res.resultSet = res.resultSet.map((kyc: Kyc) => {
						kyc.expiryDate = new Date(kyc.expiryDate);
						kyc.creationDate = new Date(kyc.creationDate);
						return kyc;
					});

					return res as TableDataResponse<Kyc>;
				})
			);
	}

	getTransactionalKycStatusesCsv(searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		return this.getCsv(ServiceAction.PLAYER_MGMT_TRANSACTIONAL_KYC, undefined, searchParams, ResponseBodyType.Text,
			undefined, xFormatParams);
	}

	getSessions(playerId: number, searchParams?: HttpParams): Observable<DataTableServerResponse> {
		return this.validateDataTableRes([], ServiceAction.PLAYER_SESSIONS, [String(playerId)], searchParams,
			ResponseBodyType.JSON, ServiceController.PLAYER_FULL);
	}

	getSessionsCsv(playerId: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const params = searchParams ?
			searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT) : searchParams;

		return this.getCsv(ServiceAction.PLAYER_SESSIONS, [String(playerId)], params,
			ResponseBodyType.Text, ServiceController.PLAYER_FULL, xFormatParams);
	}

	/**
	 * @param playerId the player id of interest
	 * @param searchParams HttpParams
	 * @param getInactive when set to true, returns all (historical) restrictions
	 */
	getRestrictions(
		playerId: number,
		searchParams?: HttpParams,
		getInactive: boolean = true
	): Observable<TableDataResponse<AccountRestriction>> {
		/*return this.get(ServiceAction.PLAYER_SESSIONS, [String(playerId)], searchParams,
			ResponseBodyType.JSON, ServiceController.PLAYER_FULL_CONTROLLER);*/
		// return of(dummyAccountRestrictionData()).pipe(delay(200));

		let params: HttpParams = searchParams ? searchParams : new HttpParams();
		params = params.set('playerID', playerId)
			.set('getInactive', getInactive);

		return this.validateDataTableRes(
			['playerRestrictionTypeID', 'restrictionName'], undefined,
			undefined, params, ResponseBodyType.JSON, ServiceController.RESTRICTIONS
		);
	}

	getRestrictionsCsv(playerId: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		let params: HttpParams = searchParams ? searchParams : new HttpParams();
		params = params.set('playerId', playerId)
			.set(FORMAT_URL_PARAM, CSV_FORMAT);

		return this.getCsv(undefined, [], params, ResponseBodyType.Text, ServiceController.RESTRICTIONS, xFormatParams);
	}

	addAccountRestriction(data: AddRestrictionRequest) {
		return this.post(undefined, data, undefined, undefined, ServiceController.RESTRICTIONS);
	}

	removeAccountRestriction(playerRestrictionID: number) {
		return this.delete(undefined, [playerRestrictionID], undefined, ServiceController.RESTRICTIONS);
	}

	/**
	 * @todo Impl once API is ready
	 */
	getSubscriptions(playerId: number, searchParams?: HttpParams): Observable<TableDataResponse<AccountSubscription>> {
		// return of(dummySubscriptionsData()).pipe(delay(200));

		return this.validateDataTableRes(
			['recurringPurchaseID', 'numbers', 'status', 'endDate'], undefined,
			[String(playerId), ServiceAction.RECURRING_PURCHASES],
			searchParams, ResponseBodyType.JSON, ServiceController.PURCHASES
		).pipe(
			map(res => {
				const len = res.resultSet.length;

				res.resultSet = res.resultSet.map((subscription: any) => {
					subscription.numbersStr = subscription.numbers.toString().replace(/,/g, ', ');

					// Parse Date objects
					subscription.createdDate = new Date(subscription.createdDate);
					subscription.startDate = new Date(subscription.startDate);
					subscription.endDate = new Date(subscription.endDate);

					return subscription;
				})

				return res as TableDataResponse<AccountSubscription>;
			}));
	}

	getSubscriptionsCsv(playerId: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const params = searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT);
		return this.getCsv(undefined, [String(playerId), ServiceAction.RECURRING_PURCHASES], params,
			ResponseBodyType.Text, ServiceController.PURCHASES, xFormatParams);
	}

	/**
	 * @todo Impl once API is ready
	 */
	getMessages(playerId: number, searchParams?: HttpParams): Observable<TableDataResponse<Message>> {
		/*return this.get(ServiceAction.PLAYER_SESSIONS, [String(playerId)], searchParams,
			ResponseBodyType.JSON, ServiceController.PLAYER_FULL_CONTROLLER);*/
		return of(dummyMessagesData()).pipe(delay(200));
	}

	getPlayerDetailsRegistered(playerId: number): Observable<PlayerDetailsResponse> {
		return this.get(ServiceAction.GET_PLAYER_DETAILS, [String(playerId)], undefined,
			ResponseBodyType.JSON, ServiceController.PLAYER_FULL).pipe(switchMap((res: PlayerDetailsResponse) => {
			const numbers = res.profile.primaryMobileNumber;

			// Parse mobile numbers
			if (numbers) {
				res.profile.primaryMobileNumber.mobileNumber = removePrefixIfExists(numbers.mobilePrefix, numbers.mobileNumber);
			}

			const numberArr = res.profile.mobileNumbers;
			if (numberArr) {
				numberArr.forEach((num, index) => {
					res.profile.mobileNumbers[index].mobileNumber = removePrefixIfExists(num.mobilePrefix, num.mobileNumber);
				});
			}

			// Parse Date objects
			res.profile.registerDate = new Date(res.profile.registerDate);
			res.profile.dateOfBirth = new Date(res.profile.dateOfBirth);

			return of(res);
		}));
	}

	getPlayerDetailsAnonymous(playerId: number): Observable<PlayerDetailsResponse> {
		return this.get(ServiceAction.GET_PLAYER_DETAILS_ANON, [String(playerId)], undefined,
			ResponseBodyType.JSON, ServiceController.PLAYER).pipe(switchMap((res: PlayerDetailsResponse) => {
			const numbers = res.profile.primaryMobileNumber;

			// Parse mobile numbers
			if (numbers) {
				res.profile.primaryMobileNumber.mobileNumber = removePrefixIfExists(numbers.mobilePrefix, numbers.mobileNumber);
			}

			const numberArr = res.profile.mobileNumbers;
			if (numberArr) {
				numberArr.forEach((num, index) => {
					res.profile.mobileNumbers[index].mobileNumber = removePrefixIfExists(num.mobilePrefix, num.mobileNumber);
				});
			}

			// Parse Date objects
			res.profile.registerDate = new Date(res.profile.registerDate);
			res.profile.dateOfBirth = new Date(res.profile.dateOfBirth);

			return of(res);
		}));
	}

	updatePlayerDetails(playerID: number, data: PlayerDetailsUpdateRequest | JsonPatchOperation[]): Observable<UpdateResponse> {
		if (data['profile']) { // PlayerDetailsUpdateRequest - PUT
			return this.post(ServiceAction.UPDATE_PLAYER_DETAILS, data,
				undefined, [String(playerID)], ServiceController.PLAYER_FULL);
		} else { // else if (data[0] && data[0]['op'] // JsonPatchOperation - PATCH
			if (!data || (data['length'] === 0)) {
				console.warn('updatePlayerDetails: empty data, therefore request aborted.');
				return of(undefined);
			}

			return this.patch(undefined, data,
				undefined, [String(playerID)], ServiceController.PLAYER_FULL);
		}
	}

	updatePlayerCommPref(playerID: number, data: UpdatePlayerCommRequest): Observable<any> {
		return this.edit(undefined, data, undefined,
			[playerID, ServiceAction.PLAYER_COMMUNICATION], ServiceController.PLAYER_FULL);
	}

	getPlayerLifetimeData(playerID: number): Observable<PlayerLifetimeData> {
		let params: HttpParams = new HttpParams();
		params = params.set('playerId', playerID.toString());
		return this.get(ServiceAction.PLAYERMANAGEMENT_LIFETIMEDATA, undefined, params);
	}

	awardAndAssignFreeTickets(postData: FreeTicketsAwardRequest) {
		return this.post(ServiceAction.AWARD_TICKETS_ASSIGN, postData,
			undefined, undefined, ServiceController.FREE_TICKETS
		);
	}

	deductFreeTickets(postData: FreeTicketsAwardRequest) {
		return this.post(ServiceAction.AWARD_TICKETS_DEDUCT, postData,
			undefined, undefined, ServiceController.FREE_TICKETS
		);
	}

	getRetailAgentByCouponIdentifier = (identifier: string): Observable<PlayerTrail[]> => {
		let params = new HttpParams();
		params = params.set('couponIdentifier', identifier);

		return this.get<PlayerTrail[]>(ServiceAction.PLAYERMANAGEMENT_GETPLAYERTRANSACTION, undefined, params);
	};

	changePlayerPassword(playerID: number, data: PlayerPasswordRequest): Observable<any> {
		return this.edit(undefined, data, undefined,
			[playerID, ServiceAction.PLAYER_CHANGE_PASSWORD], ServiceController.PLAYER_FULL);
	}
}
