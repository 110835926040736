import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonTable} from '../../../interfaces/common-table';
import {BankAccount, BankAccountRequest} from '../../../models/finance/transfer-information.model';
import {Subscription} from 'rxjs';
import {AppConfigService, BoErrorHandlerService, ConfirmDialogDisplayService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute} from '@angular/router';
import {BankCardService} from '../../../../player-management/shared/services/bank-card.service';
import * as ColumnNames from '../../../constants/ui-db-name-mappings';
import {ColumnType, DataTableActionCallback, DataTableLazyLoadEvent} from '../../data-table-v3';
import {Confirmation} from 'primeng/api';
import {DIALOG_CONFIRM_MSG_NORMAL} from '../../../constants/constants';
import {ToastMessageType} from '../../../models';
import {HttpParams} from '@angular/common/http';
import {Player} from '../../../models/player/players.model';

@Component({
	selector: 'he-bank-details-dialog',
	templateUrl: './bank-details-dialog.component.html',
	styleUrls: ['./bank-details-dialog.component.scss']
})
export class BankDetailsDialogComponent extends CommonTable<BankAccount> implements OnInit, OnDestroy {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() playerPartialData: Player;

	@Output() closeEvent = new EventEmitter<void>();

	private routeSubs$: Subscription;
	private getTableDataSub$: Subscription;
	private createRowSub$: Subscription;
	private updateRowSub$: Subscription;
	private deleteRowSub$: Subscription;

	routePlayerID: number;

	showCreateDialog = false;
	showEditDialog = false;

	selectedRow: BankAccount;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private bankCardService: BankCardService,
				private confirmationDisplayService: ConfirmDialogDisplayService,
				private toastDisplayService: ToastDisplayService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.initDataTable();

		this.routeSubs$ = this.route.params.subscribe(params => {
			this.routePlayerID = params.id;

			if (this.params) {
				this.getTableData(undefined);
			}
		});

		this.getTableData();
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.getTableDataSub$, this.createRowSub$,
			this.updateRowSub$, this.deleteRowSub$, this.routeSubs$
		);
	}

	initDataTable() {
		this.dataKey = ColumnNames.BANK_ACCOUNT_PAYMENT_ID.DB;

		this.cols = [
			{
				field: ColumnNames.BANK_ACCOUNT_PAYMENT_ID.DB,
				header: ColumnNames.BANK_ACCOUNT_PAYMENT_ID.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_BENEFICIARY.DB,
				header: ColumnNames.BANK_ACCOUNT_BENEFICIARY.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_BANK_NAME.DB,
				header: ColumnNames.BANK_ACCOUNT_BANK_NAME.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_BANK_BRANCH.DB,
				header: ColumnNames.BANK_ACCOUNT_BANK_BRANCH.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_BANK_CITY.DB,
				header: ColumnNames.BANK_ACCOUNT_BANK_CITY.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_BANK_STATE.DB,
				header: ColumnNames.BANK_ACCOUNT_BANK_STATE.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_COUNTRY.DB,
				header: ColumnNames.BANK_ACCOUNT_COUNTRY.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_NUMBER.DB,
				header: ColumnNames.BANK_ACCOUNT_NUMBER.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_SORT_CODE.DB,
				header: ColumnNames.BANK_ACCOUNT_SORT_CODE.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_SWIFT_CODE.DB,
				header: ColumnNames.BANK_ACCOUNT_SWIFT_CODE.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_IBAN.DB,
				header: ColumnNames.BANK_ACCOUNT_IBAN.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_IFSC.DB,
				header: ColumnNames.BANK_ACCOUNT_IFSC.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_ROUTING_NUMBER.DB,
				header: ColumnNames.BANK_ACCOUNT_ROUTING_NUMBER.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_NAT_ID_NUMBER.DB,
				header: ColumnNames.BANK_ACCOUNT_NAT_ID_NUMBER.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_WITHDRAWAL_METHOD.DB,
				header: ColumnNames.BANK_ACCOUNT_WITHDRAWAL_METHOD.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_ACCOUNT_REF.DB,
				header: ColumnNames.BANK_ACCOUNT_ACCOUNT_REF.UI
			},
			{
				field: ColumnNames.BANK_ACCOUNT_DATE_CREATED.DB,
				header: ColumnNames.BANK_ACCOUNT_DATE_CREATED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.BANK_ACCOUNT_DATE_LAST_USED.DB,
				header: ColumnNames.BANK_ACCOUNT_DATE_LAST_USED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.BANK_ACCOUNT_DATE_LAST_UPDATED.DB,
				header: ColumnNames.BANK_ACCOUNT_DATE_LAST_UPDATED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.BANK_ACCOUNT_IS_DEFAULT.DB,
				header: ColumnNames.BANK_ACCOUNT_IS_DEFAULT.UI,
				columnType: ColumnType.TrueFalse
			}
		];

		this.tableActions = [
			{
				menuItem: {
					label: 'Edit',
					icon: 'pi pi-pencil'
				},
				callback: (callbackObj: DataTableActionCallback) => {
					this.selectedRow = callbackObj.data[0];
					this.showEditDialog = true;
				}
			},
			{
				menuItem: {
					label: 'Delete',
					icon: 'ui-icon-delete'
				},
				callback: (callbackObj) => {
					const selectedRow: BankAccount = callbackObj.data[0];

					const confMessage: Confirmation = {
						message: `${DIALOG_CONFIRM_MSG_NORMAL} This will delete the Bank Account with ID ${selectedRow.playerPaymentAccountID}.`,
						accept: () => {
							this.onDeleteRowSubmit(selectedRow.playerPaymentAccountID);
						},
						reject: () => {
							// Do nothing
						}
					};

					this.confirmationDisplayService.showConfirmDialog(confMessage);
				}
			}
		];

		this.searchFields = []

		this.fetchLookups();
	}

	fetchLookups() {
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTableDataHelper();
	}

	private getTableDataHelper() {
		this.getTableDataSub$ = this.bankCardService.getBankAccounts(this.routePlayerID, this.params).subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	onCreateRowSubmit(request: BankAccountRequest) {
		request.playerID = this.routePlayerID;

		this.beforeTableDataServiceCall(this.createRowSub$);

		this.createRowSub$ = this.bankCardService.addBankAccount(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Bank details added.'
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}

	onEditRowSubmit(request: BankAccountRequest) {
		request.playerPaymentAccountID = this.selectedRow.playerPaymentAccountID;

		this.beforeTableDataServiceCall(this.updateRowSub$);

		this.updateRowSub$ = this.bankCardService.updateBankAccount(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Bank details updated.'
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}

	onDeleteRowSubmit(playerPaymentAccountID: number) {
		this.loading = true;
		this.releaseSubscriptions(this.deleteRowSub$);

		this.deleteRowSub$ = this.bankCardService.deleteBankAccount(playerPaymentAccountID).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `Bank details deleted.`
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}
}
